export const formatPrice = (price: number): string => {
  return price.toLocaleString('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formatAddress = (
  street: string,
  streetNumber: string,
  streetNumberSuffix: string,
  postcode: string,
  town: string
): string => {
  return `${street} ${streetNumber}${
    streetNumberSuffix ? ` ${streetNumberSuffix}` : ''
  }, ${postcode} ${town}`;
};

type AnyObject = { [key: string]: any };

export const extractKeysWithValues = (obj: AnyObject): string[] => {
  const result: string[] = [];

  for (const key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      // If it's an object, include only the top-level key
      result.push(key);
    } else if (obj[key] !== undefined && obj[key] !== null && obj[key] !== '') {
      // If it's a non-empty value, include the key
      result.push(key);
    }
  }

  return result;
};

export const createValidationErrorMessage = (errors: any) => {
  const text =
    'Bitte stellen Sie sicher, dass alle erforderlichen Felder ausgefüllt sind. In den folgenden Abschnitten liegen Validierungsfehler vor: ';
  const invalidSections = extractKeysWithValues(errors);

  const sections: Record<string, string> = {
    sepaPaymentTerms: 'SEPA Einzugsermächtigung',
    paymentData: 'SEPA Einzugsermächtigung - Kontoinhaber',
    iban: 'SEPA Einzugsermächtigung',
    personalInfo: 'Auftraggeber',
    installationAddressData: 'Auftraggeber',
    shippingData: 'Versandinformationen',
    invoiceData: 'Rechnungsadresse',
    propertyOwnerData: 'Grundstückseigentümer',
    contactPersonData: 'Kontaktperson für Hausbegehung'
  };

  let validationErrors = '';
  invalidSections.forEach((sec) => {
    if (Object.keys(sections).includes(sec)) {
      validationErrors += ` ${sections[sec]},`;
    }
  });

  const wordsArray = validationErrors.split(',').map((word) => word.trim());
  const uniqueWordsSet = new Set(wordsArray);
  const resultString = Array.from(uniqueWordsSet).join(', ');

  return text.concat(resultString).slice(0, -2);
};

export const phoneIsValid = (phone: string): boolean => {
  const pattern = /^(?:\+\d+|0\d+)$/;
  return pattern.test(phone);
};
