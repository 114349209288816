import { EffectCallback, useEffect, useState, useRef } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Packages, PaymentData } from 'types/bundle';
import { RootState } from 'store/store';

import {
  selectedPaymentData,
  setPaymentData
} from 'store/slices/selectedDataSlice';

import {
  getIbanData,
  setSelectedPaymentOption,
  setPaymentRequired,
  setShowAdditionalPayment
} from 'store/slices/ibanSlice';

import useShortcutListener from 'components/Shortcuts/shortcutListener';

import { Shortcut } from 'components/Shortcuts';

import {
  Checkbox,
  CheckboxProps,
  Col,
  Radio,
  RadioChangeEvent,
  Row,
  Spin
} from 'antd';

import FloatingLabelInput from 'components/FloatingLabelInput';
import FloatingLabelSelect from 'components/FloatingLabelSelect';
import {
  setValidationErrors,
  validationErrors
} from 'store/slices/validationSlice';

import CheckIconGreen from 'assets/images/svg/check_icon_green.svg';

import style from './style.module.css';
import ErrorMessage from 'components/ErrorMessage';
import { selectedCurrentPackage } from 'store/slices/categoriesSlice';
import {
  ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE,
  ENTER_PERSONAL_INFO_MESSAGE,
  TITLE_NOT_SELECTED
} from 'constants/Constants';
import { getTitleOptions } from 'utils/Utils';

const SepaDirectDebitMandate = () => {
  const dispatch = useDispatch();

  const componentRef = useRef<HTMLDivElement | null>(null);

  const { data, status, selectedPaymentOption, showPaymentOption } =
    useSelector((state: RootState) => state.iban);

  const paymentData: PaymentData = useSelector(selectedPaymentData);

  const errors = useSelector(validationErrors);

  const currentPackage: Packages = useSelector(selectedCurrentPackage);

  const [accountOwnerOption, setAccountOwnerOption] = useState<boolean>(
    paymentData.equalToCustomer
  );

  const [paymentOption, setPaymentOption] = useState<string>(
    selectedPaymentOption
  );

  const [showAdditionalPaymentOption, setShowAdditionalPaymentOption] =
    useState<boolean>(showPaymentOption);

  useShortcutListener(Shortcut.SHIFT_B, () => {
    setShowAdditionalPaymentOption(true);
    dispatch(setShowAdditionalPayment(true));
  });

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    dispatch(
      setPaymentData({
        ...paymentData,
        [name]: value
      })
    );
  };

  const onTitleChange = (value: string) => {
    dispatch(
      setPaymentData({
        ...paymentData,
        account_owner_title: value
      })
    );
  };

  const handleAccountOwnerChange = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setAccountOwnerOption(value);

    dispatch(
      setPaymentData({
        ...paymentData,
        equalToCustomer: value
      })
    );
  };

  const handleTermsAgreement: CheckboxProps['onChange'] = (e) => {
    const { checked } = e.target;

    dispatch(
      setPaymentData({
        ...paymentData,
        accept_payment_terms: checked
      })
    );
  };

  const getIban = () => {
    if (paymentData?.iban.length < 15) {
      dispatch(
        setValidationErrors({
          ...errors,
          iban: 'Iban sollte mindestens 15 Zeichen lang sein'
        })
      );
    } else {
      dispatch(getIbanData(paymentData.iban || '0') as any);
      dispatch(setValidationErrors({ ...errors, iban: 'Erforderlich' }));
    }
  };

  const handleOnKeyPress = () => {
    dispatch(
      setPaymentData({
        ...paymentData,
        manuallyTyped: true
      })
    );
  };

  const handleIbanData = () => {
    if (status === 'succeeded' && data?.valid) {
      dispatch(
        setValidationErrors({
          ...errors,
          iban: '',
          bic: data.bic || paymentData.bic ? '' : 'Erforderlich',
          creditInstitution:
            data.bankname || paymentData.credit_institution
              ? ''
              : 'Erforderlich'
        })
      );

      dispatch(
        setPaymentData({
          ...paymentData,
          iban: data.iban,
          bic: data.bic ?? '',
          credit_institution: data.bankname ?? '',
          valid: data.valid,
          manuallyTyped: false
        })
      );

      if (paymentData.manuallyTyped) {
        dispatch(
          setPaymentData({
            ...paymentData,
            bic: data.bic ?? paymentData?.bic,
            credit_institution: data.bankname ?? paymentData.credit_institution,
            manuallyTyped: false
          })
        );
      }
    }

    if (status === 'succeeded' && !data?.valid) {
      dispatch(
        setPaymentData({
          ...paymentData,
          bic: '',
          credit_institution: '',
          valid: data.valid
        })
      );
      dispatch(
        setValidationErrors({
          ...errors,
          bic: data.bic ? '' : 'Erforderlich',
          creditInstitution: data.bankname ? '' : 'Erforderlich',
          iban: 'Iban ist ungültig'
        })
      );
    }
  };

  const handlePaymentOption = (e: RadioChangeEvent) => {
    const { value } = e.target;

    setPaymentOption(value);

    dispatch(setSelectedPaymentOption(value));

    if (value === 'sepa-lastschrifteinzug') {
      dispatch(setPaymentRequired(true));
    } else {
      dispatch(setPaymentRequired(false));
    }
  };

  useEffect((): ReturnType<EffectCallback> => {
    handleIbanData();
  }, [data, status]);

  useEffect(() => {
    if (
      !errors?.iban &&
      !errors?.bic &&
      !errors?.creditInstitution &&
      errors?.sepaPaymentTerms &&
      componentRef.current
    ) {
      componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [errors?.sepaPaymentTerms && componentRef.current]);

  if (status === 'loading') {
    return <Spin fullscreen tip="Loading" />;
  }

  return (
    <div className={style.sepa_direct_container}>
      {showAdditionalPaymentOption && (
        <>
          <h3 className={style.subsection_title}>Zahlung per</h3>

          <Radio.Group
            value={paymentOption}
            onChange={handlePaymentOption}
            className="margin_bottom_l"
          >
            <Row
              justify={'start'}
              gutter={[0, 24]}
              className={style.radio_wrapper}
            >
              <Col span={24} xl={7}>
                <Radio value={'sepa-lastschrifteinzug'}>
                  SEPA-Lastschrifteinzug
                </Radio>
              </Col>

              <Col span={24} xl={12}>
                <Radio value={'banküberweisung'}>Banküberweisung</Radio>
              </Col>
            </Row>
          </Radio.Group>
        </>
      )}

      {paymentOption === 'sepa-lastschrifteinzug' && (
        <>
          <Row className={'margin_bottom_m' + ' ' + style.no_margin}>
            <Col span={24}>
              <FloatingLabelInput
                label="IBAN*"
                value={paymentData.iban}
                onChangeHandler={onChangeHandler}
                name="iban"
                onBlur={paymentData.iban !== '' ? getIban : null}
                errorStatus={errors?.iban ? 'error' : ''}
                errorMessage={errors?.iban ? errors?.iban : ''}
              />
              {data?.valid && !errors.iban && (
                <p className={style.iban_valid_text}>
                  <img src={CheckIconGreen} /> IBAN Nummer ist korrekt.
                </p>
              )}
            </Col>
          </Row>

          <Row gutter={12} className="margin_bottom_l">
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="BIC*"
                value={paymentData.bic}
                onChangeHandler={onChangeHandler}
                name="bic"
                disabled={Boolean(data?.bic)}
                errorStatus={
                  errors.bic && paymentData.bic === '' ? 'error' : ''
                }
                errorMessage={
                  errors.bic && paymentData.bic === '' ? errors.bic : null
                }
                onKeyDown={handleOnKeyPress}
              />
            </Col>
            <Col span={24} md={12}>
              <FloatingLabelInput
                label="Kreditinstitut*"
                value={paymentData.credit_institution}
                onChangeHandler={onChangeHandler}
                name="credit_institution"
                disabled={Boolean(data.bankname)}
                errorStatus={
                  errors.creditInstitution &&
                  paymentData.credit_institution === ''
                    ? 'error'
                    : ''
                }
                errorMessage={
                  errors.creditInstitution &&
                  paymentData.credit_institution === ''
                    ? errors.creditInstitution
                    : null
                }
                onKeyDown={handleOnKeyPress}
              />
            </Col>
          </Row>

          <h3 className={style.subsection_title}>Kontoinhaber</h3>

          <Radio.Group
            value={accountOwnerOption}
            onChange={handleAccountOwnerChange}
            className="margin_bottom_l"
          >
            <Row
              justify={'start'}
              gutter={[0, 24]}
              className={style.radio_wrapper}
            >
              <Col span={24} xl={6}>
                <Radio value={true}>Auftraggeber</Radio>
              </Col>
              <Col span={24} xl={12}>
                <Radio value={false}>Sonstige (bitte angeben)</Radio>
              </Col>
            </Row>
          </Radio.Group>

          {!accountOwnerOption && (
            <>
              {(errors.paymentData?.account_owner_company ||
                (errors.paymentData?.account_owner_title &&
                  paymentData.account_owner_title === '') ||
                (errors.paymentData?.account_owner_name &&
                  paymentData.account_owner_name === '') ||
                (errors.paymentData?.account_owner_last_name &&
                  paymentData.account_owner_last_name === '')) && (
                <div className="error_message_wrapper">
                  <ErrorMessage
                    errorMessage={
                      currentPackage?.customer_group_firm
                        ? ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE
                        : ENTER_PERSONAL_INFO_MESSAGE
                    }
                  />
                </div>
              )}

              {currentPackage?.customer_group_firm && (
                <Row className="connection_owner_info_wrapper">
                  <Col span={24}>
                    <FloatingLabelInput
                      label="Firma*"
                      value={paymentData?.account_owner_company}
                      onChangeHandler={onChangeHandler}
                      name="account_owner_company"
                      errorStatus={
                        errors?.paymentData?.account_owner_company
                          ? 'error'
                          : ''
                      }
                      errorMessage={errors?.paymentData?.account_owner_company}
                    />
                  </Col>
                </Row>
              )}

              <Row gutter={12} className="connection_owner_info_wrapper">
                <Col span={8} md={6}>
                  <FloatingLabelSelect
                    value={paymentData.account_owner_title}
                    label="Anrede*"
                    options={getTitleOptions(
                      currentPackage?.customer_group_firm
                    )}
                    onChangeHandler={onTitleChange}
                    errorStatus={
                      errors?.paymentData?.account_owner_title &&
                      (paymentData.account_owner_title === '' ||
                        paymentData.account_owner_title === TITLE_NOT_SELECTED)
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      errors?.paymentData?.account_owner_title &&
                      (paymentData.account_owner_title === '' ||
                        paymentData.account_owner_title === TITLE_NOT_SELECTED)
                        ? errors?.paymentData?.account_owner_title
                        : undefined
                    }
                  />
                </Col>
                <Col span={16} md={9}>
                  <FloatingLabelInput
                    name="account_owner_name"
                    label="Vorname*"
                    value={paymentData.account_owner_name}
                    onChangeHandler={onChangeHandler}
                    errorStatus={
                      errors?.paymentData?.account_owner_name &&
                      paymentData.account_owner_name === ''
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      errors?.paymentData?.account_owner_name &&
                      paymentData.account_owner_name === ''
                        ? errors?.paymentData?.account_owner_name
                        : null
                    }
                  />
                </Col>
                <Col span={24} md={9}>
                  <FloatingLabelInput
                    name="account_owner_last_name"
                    label="Nachname*"
                    value={paymentData.account_owner_last_name}
                    onChangeHandler={onChangeHandler}
                    errorStatus={
                      errors?.paymentData?.account_owner_last_name &&
                      paymentData.account_owner_last_name === ''
                        ? 'error'
                        : ''
                    }
                    errorMessage={
                      errors?.paymentData?.account_owner_last_name &&
                      paymentData.account_owner_last_name === ''
                        ? errors?.paymentData?.account_owner_last_name
                        : null
                    }
                  />
                </Col>
              </Row>
            </>
          )}
          <Row>
            <Col span={24} className={`margin_bottom_l ${style.checkbox_sm}`}>
              <Checkbox
                className={
                  errors.sepaPaymentTerms
                    ? `custom_style_checkbox_error`
                    : `custom_style_checkbox`
                }
                checked={paymentData.accept_payment_terms}
                onChange={handleTermsAgreement}
              >
                <span
                  className={
                    errors.sepaPaymentTerms && !paymentData.accept_payment_terms
                      ? 'error_text_style'
                      : 'package_speed'
                  }
                  ref={componentRef}
                >
                  {`Ich stimme der Ermächtigung zu, dass die MUENET GmbH & Co. KG bis auf den Widerruf zum Einzug der fälligen Beiträge von meinem Konto mittels SEPA-Lastschrift ermächtigt wird. Zugleich weise ich mein Kreditinstitut an, die von der MUENET GmbH & Co. KG auf mein Konto gezogenen Lastschriften einzulösen. `}
                </span>
              </Checkbox>
            </Col>

            <Col span={24} offset={1}>
              <span className="package_speed">{`Hinweis: Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen`}</span>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default SepaDirectDebitMandate;
