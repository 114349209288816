export const SHIPPING_PRODUCT_ID = 29;
export const ADDITIONAL_PHONE_NUMBER_PRODUCT_ID = 443;
export const CONVERSATION_CHANNEL_PRODUCT_ID = 307;
export const PORT_PHONE_NUMBERS_PRODUCT_ID = 729;

export const TAX_INCLUDED_MESSAGE =
  '*Alle genannten Preise verstehen sich netto, zuzüglich der jeweils gültigen gesetzlichen Mehrwertsteuer.';
export const TAX_NOT_INCLUDED_MESSAGE =
  '*Alle genannten Preise verstehen sich inklusive der jeweils gültigen gesetzlichen Mehrwertsteuer.';

export const EQUAL_TO_CUSTOMER_MESSAGE = 'Mit Auftraggeber identisch';
export const ENTER_COMPANY_OR_PERSONAL_INFO_MESSAGE =
  'Entweder Firma oder Anrede, Vorname und Nachname müssen angegeben werden';
export const ENTER_PERSONAL_INFO_MESSAGE =
  'Anrede, Vorname und Nachname müssen angegeben werden';

export const TITLE_NOT_SELECTED = '[Bitte auswählen]';
